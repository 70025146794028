import React, { useEffect, useState } from "react";
import styles from "./Payment.module.css";
import PaymentMethod from "../components/PaymentPage/PaymentMethod";
import PaymentNetwork from "../components/PaymentPage/PaymentNetwork";
import PaymentCoin from "../components/PaymentPage/PaymentCoin";
import PayWithWallet from "../components/PaymentPage/PayWithWallet";
import { formatTime } from "../utils/formatTime";
import useTimer from "../hooks/useTimer";
import { useParams } from "react-router-dom";

const Payment = () => {
	const timerSec = 1200;
	const [isActiveTab, setIsActiveTab] = useState(false);
	const [cardStep, setCardStep] = useState("method");
	const { timeRemaining, isLastTime } = useTimer(timerSec, isActiveTab, !isActiveTab);
	const [walletPaymentData, setWalletPaymentData] = useState({
		network: {},
		coin: {},
	});

	let {invoiceID}  = useParams();
	useEffect(() => {
		if (invoiceID == "undefined") {
			window.location.replace(process.env.REACT_APP_HOMEPAGE);
		}
		if(invoiceID){

			// call api to get invoice data
			localStorage.setItem("invoice", invoiceID);
		}  
		else {
			let homepage = process.env.REACT_APP_HOMEPAGE;
			window.location.replace(homepage);
		} 
	}, [invoiceID]);

	useEffect(() => {
		if (cardStep === "payWallet") setIsActiveTab(true);
		else setIsActiveTab(false);
	}, [cardStep]);

	const cardStepHandler = (step) => {
		setCardStep(step);
		if (step === "payWallet") setIsActiveTab(true);
	};

	return (
		<div className={styles.container}>
			{cardStep === "payWallet" && (
				<div className={styles.timeout} dir="rtl">
					<div className={styles.fill} style={{ animation: `fillAnimation ${timerSec}s linear` }}>
						<div className={`${styles.tooltip} ${isLastTime ? styles.lastTime : ""}`}>
							<div className={styles.arrowDown}></div>
							<p>
								Timer: <span>{formatTime(timeRemaining)}</span>
							</p>
						</div>
					</div>
				</div>
			)}
			<div className={styles.paymentCard}>
				<div className={styles.head}>
					<button
						className={styles.backBtn}
						onClick={() => {
							if (cardStep === "network") setCardStep("method");
							else if (cardStep === "coin") setCardStep("network");
							else if (cardStep === "payWallet") setCardStep("coin");
						}}
					>

						{cardStep === "method" ? <img src="/icons/wallet.svg" alt="left icon" /> : <img src="/icons/arrowLeft.svg" alt="" />}
						
					</button>
					<div className={styles.info}>
						{cardStep === "method" && (
							<>
								<h1>{process.env.REACT_APP_APPNAME + " Checkout"}</h1>
								<p>Invoice ID : {localStorage.getItem("invoice")}</p>
							</>
						)}

						{cardStep === "coin" && (
							<>
								<h1>Select your payment Coin</h1>
								<p>Select Payment Currency.</p>
							</>
						)}
						{cardStep === "network" && (
							<>
								<h1>Select your payment network</h1>
								<p>Select Payment Network.</p>
							</>
						)}
						{cardStep === "payWallet" && (
							<>
								<h1>Pay With Wallet</h1>
								<p>Simple and flexible per-user pricing.</p>
							</>
						)}
					</div>
				</div>
				<div className={styles.body}>
					{cardStep === "method" && <PaymentMethod cardStepHandler={cardStepHandler} />}
					{cardStep === "coin" && (
						<PaymentCoin
							cardStepHandler={cardStepHandler}
							selectedCoin={(coin) => {
								setWalletPaymentData((prev) => ({ ...prev, coin: coin }));
							}}
						/>
					)}
					{cardStep === "network" && (
						<PaymentNetwork
							cardStepHandler={cardStepHandler}
							selectedNetwork={(net) => {
								setWalletPaymentData((prev) => ({ ...prev, network: net }));
							}}
						/>
					)}
					{cardStep === "payWallet" && <PayWithWallet walletPaymentData={walletPaymentData} />}
				</div>
			</div>
		</div>
	);
};

export default Payment;
