import { useState, useEffect } from 'react';

const useTimer = (duration, isActive, shouldReset) => {
    const [timeRemaining, setTimeRemaining] = useState(duration);
    const [isLastTime, setIsLastTime] = useState(false);

    useEffect(() => {
        if (shouldReset) {
            setTimeRemaining(duration);
            setIsLastTime(false);
        }

        if (!isActive) {
            return;
        }
        const timer = setInterval(() => {
            setTimeRemaining((prevTime) => {
                if (prevTime < (duration * 20) / 100) setIsLastTime(true);
                if (prevTime <= 0) {
                    clearInterval(timer);
                    return 0;
                }
                return prevTime - 1;
            });
        }, 1000);

        return () => clearInterval(timer);
    }, [duration, isActive, shouldReset]);

    return { timeRemaining, isLastTime };
};

export default useTimer;