import React, { useState } from "react";
import styles from "./PaymentCoin.module.css";
import { currencies } from "../../fakeData";

const PaymentCoin = ({ cardStepHandler, selectedCoin }) => {
	const [coinsData, setCoinsData] = useState(currencies);
	const [selectedNet, setSelectedNet] = useState();
	const [isDisabled, setIsDisabled] = useState(false);
	const [search, setSearch] = useState("");
	const [alertmsg, setAlertmsg] = useState("");

	const handleSearch = (event) => {
		const searchTerm = event.target.value.toLowerCase();
		if (searchTerm === "") setCoinsData(currencies);
		setSearch(searchTerm);

		const filteredCoins = currencies.filter((item) => {
			const lowerCaseTitle = item.title.toLowerCase();
			const lowerCaseAbbr = item.abbr.toLowerCase();

			return lowerCaseTitle.includes(searchTerm) || lowerCaseAbbr.includes(searchTerm);
		});

		setCoinsData(filteredCoins);
	};
	const handleProcess = () => {
		setIsDisabled(true);
		setAlertmsg("");
		if (!selectedNet) {
			setAlertmsg("Please select a coin !");
			setIsDisabled(false);
			return;
		}
		selectedCoin(selectedNet);
		cardStepHandler("payWallet");
		setIsDisabled(false);
		createPayment();
	};
	async function createPayment() {
		alert("generating payment");
	}
	return (
		<div className={styles.container}>
			<div className={styles.searchbar}>
				<img src="/icons/search.svg" alt="search" />
				<input className={styles.child} type="text" name="network" placeholder="Find Your Preferred Coin" value={search} onChange={handleSearch} />
			</div>
			<div className={styles.networkList}>
				{coinsData.length > 0 ? (
					coinsData.
					filter((item) => item.supported_networks.includes(localStorage.getItem("network"))).
					map((item) => (
						<div key={item.id} className={`${styles.item} ${selectedNet === item ? styles.selectedItem : ""}`} onClick={() => setSelectedNet(item)}>
							<div>
								<img className={styles.icon} src={item.img_url} alt={item.title} />
								<h2>{item.title}</h2>
								<p>{item.abbr} </p>
							</div>
							<div>{selectedNet === item && <img src="/icons/selectbox.svg" alt="selectbox icon"></img>}</div>
						</div>
					))
				) : (
					<h2 style={{ color: "var(--text_color_low" }}>no network!</h2>
				)}
			</div>
			{alertmsg && <p className={styles.alertMessage}>{alertmsg}</p>}
			<button type="button" onClick={handleProcess} disabled={isDisabled}>
				Proceed
			</button>
		</div>
	);
};

export default PaymentCoin;
