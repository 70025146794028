import React from "react";
import styles from "./PaymentMethod.module.css";

const PaymentMethod = ({ cardStepHandler }) => {
	return (
		<div className={styles.container}>
			<div className={styles.option} onClick={() => cardStepHandler("network")}>
				<div className={styles.info}>
					<img src="/icons/walletFill.svg" alt="wallet" />
					<h2>Pay with Wallet</h2>
				</div>
				<button type="button">
					<div>
						<img src="/coins/bitcoin.svg" alt="bitcoin icon" />
						<img src="/coins/ethereum.svg" alt="ethereum icon" />
						<img src="/coins/polygon.svg" alt="polygon icon" />
					</div>
					<img src="/icons/arrowRight.svg" alt="right icon" />
				</button>
			</div>
			<div className={styles.option}>
				<div className={styles.info}>
					<img src="/icons/cardBankFill.svg" alt="cardBank" />
					<h2>Card Bank</h2>
				</div>
				<button type="button">
					<div>
						<img src="/icons/mastercard.svg" alt="masketcard icon" />
						<img src="/icons/visacard.svg" alt=" icon" />
					</div>
					<img src="/icons/arrowRight.svg" alt="right icon" />
				</button>
			</div>
			<div className={styles.balance}>
				<div className={styles.option}>
					<div className={styles.info}>
						<img src="/icons/piggyBankFill.svg" alt="piggy bank icon" />
						<h2>Balance</h2>
					</div>
					<button type="button">
						<img src="/icons/arrowRight.svg" alt="right icon" />
					</button>
				</div>
				<div className={styles.foot}>
					<p>
						Your Balance Is: <span>$0</span>
					</p>
					<span>2% Cashback</span>
				</div>
			</div>
		</div>
	);
};

export default PaymentMethod;
