export const currencies = [
    {
        id: 0,
        title: 'Bitcoin',
        abbr: "BTC",
        supported_networks: ["bitcoin"],
        img_url: "/coins/bitcoin.svg"
    },

    {
        id: 0,
        title: 'Ethereum',
        abbr: "ETH",
        supported_networks: ["ethereum"],
        img_url: "/coins/ethereum.svg"
    },

    {
        id: 0,
        title: 'Tether',
        abbr: "USDT",
        supported_networks: ["ethereum"],
        img_url: "https://s2.coinmarketcap.com/static/img/coins/64x64/825.png"
    },

    {
        id: 0,
        title: 'USD Coin',
        abbr: "USDC",
        supported_networks: ["ethereum"],
        img_url: "https://s2.coinmarketcap.com/static/img/coins/64x64/3408.png"
    },
]


export const networks = [
    {
        id: 0,
        network_id: 'bitcoin',
        network_name: "Bitcoin Network",
        img_url: "/coins/bitcoin.svg"
    },
    {
        id: 1,
        network_id: 'ethereum',
        network_name: "Ethereum Network",
        img_url: "/coins/ethereum.svg"
    }
]