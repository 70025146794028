import React from "react";
import styles from "./Header.module.css";

const Header = () => {
	return (
		<header className={styles.header}>
			<div className={styles.container}>
				<div className={styles.left}>
					<div className={styles.burgerMenue}>
						<img src="/icons/burgerMenu.svg" alt="menu icon" />
					</div>
					<div className={styles.logo}>
						<img width={200} src={process.env.REACT_APP_LOGO} alt="logo" />
					</div>
					{/* <nav className={styles.navbar}>
						<ul>
							<li>
								<a href="#">
									<span>Home</span>
								</a>
							</li>
							<li>
								<span>Products </span>
								<img src="/icons/arrowDown.svg" alt="down icon" />
							</li>
							<li>
								<span>Resources</span>
								<img src="/icons/arrowDown.svg" alt="down icon" />
							</li>
							<li>
								<a href="#">
									<span>Pricing</span>
								</a>
							</li>
						</ul>
					</nav> */}
				</div>
				<div className={styles.right}>
					<div className={styles.btns}>
						<button type="button" className={styles.login}>
							Log in
						</button>
						<button type="button" className={styles.signup}>
							Sign up
						</button>
					</div>
				</div>
			</div>
		</header>
	);
};

export default Header;
