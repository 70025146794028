import React from 'react';
import { Routes, Route } from "react-router-dom";
import Payment from './pages/Payment';
import Header from './components/Header/Header';

function App() {
  return (
    <div className="App">
      <Header />
      <Routes>
        <Route path="/:invoiceID" element={<Payment />}></Route>
        <Route path="/" element={<Payment />}></Route>
      </Routes>
    </div>
  );
}

export default App;
