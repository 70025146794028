import { useEffect } from "react";
import styles from "./Modal.module.css";

const Modal = ({ children, modalOpen, handleCloseModal, width }) => {
	useEffect(() => {
		if (modalOpen) {
			document.body.style.overflow = "hidden";
		} else {
			document.body.style.overflow = "unset";
		}
		return () => {
			document.body.style.overflow = "unset";
		};
	}, [modalOpen]);

	const handleClose = () => {
		handleCloseModal && handleCloseModal();
	};

	const handleBackdropClick = (e) => {
		if (e.target === e.currentTarget) {
			handleClose();
		}
	};

	if (!modalOpen) {
		return null;
	}

	return (
		<div className={styles.modalBackdrop} onClick={handleBackdropClick}>
			<div className={styles.modal} style={{ width: width }}>
				<button className={styles.closeButton} onClick={handleClose}>
					<img src={"/icons/close.svg"} alt="بستن" />
				</button>
				{children}
			</div>
		</div>
	);
};

export default Modal;
