import React, { useRef, useState } from "react";
import styles from "./PayWithWallet.module.css";
import Modal from "../Modal/Modal";

const PayWithWallet = ({ walletPaymentData }) => {
	const { coin, network } = walletPaymentData;
	const [copyAlert, setCopyAlert] = useState(false);
	const addressRef = useRef(null);
	const [modalOpen, setModalOpen] = useState(false);

	const handleCloseModal = () => setModalOpen(false);

	const handlePayProcess = () => {
		/* pay process is happening here */
		handleCloseModal(true);
	};

	const handleCopyClick = () => {
		setCopyAlert("");
		if (addressRef.current.value) {
			navigator.clipboard.writeText(addressRef.current.value).then(() => {
				setCopyAlert(true);
				setTimeout(() => {
					setCopyAlert(false); // Remove the "Copied!" message after 2 seconds
				}, 2000);
			});
		}
	};

	return (
		<div className={styles.container}>
			<div className={styles.selects}>
				<p>Select Coin & Network</p>
				<div>
					<div className={styles.coin}>
						<img src={coin.imgSrc} alt={coin.title} />
						<p>{coin.title}</p>
					</div>
					<div className={styles.network}>
						<p>
							<span>Network: </span>
							{localStorage.getItem("network_name") ? localStorage.getItem("network_name") : "Null"}
						</p>
					</div>
				</div>
			</div>
			<div className={styles.payInfo}>
				<div className={styles.info}>
					<div className={styles.amount}>
						<p>Amount To Pay:</p>
						<span>0.00585 BNB</span>
					</div>
					<div className={styles.convert}>
						<p>Currency Conversation:</p>
						<span>1 BNB = 345.92 USDT</span>
					</div>
					<div className={styles.address}>
						<input type="text" ref={addressRef} value="0x1395c39asdsdasdasdasdssvsadffssdfdsfsdfsdfsfsddasdasd" readOnly={true}></input>

						<button type="button" onClick={handleCopyClick}>
							{copyAlert ? (
								<p>Copied!</p>
							) : (
								<>
									{" "}
									<img src="/icons/copy.svg" alt="copy" />
									<p>Copy</p>
								</>
							)}
						</button>
					</div>
				</div>
				<div className={styles.qrcode}>
					<img src="/images/qrcode.png" alt="qrcode" />
				</div>
			</div>
			<button className={styles.payBtn} onClick={() => setModalOpen(true)}>
				<img src="/icons/walletConnect.svg" alt="walletConnect icon" />
				<p> Pay with WalletConnect</p>
			</button>
			<Modal handleCloseModal={handlePayProcess} modalOpen={modalOpen} width={"400px"}>
				<div className={styles.modalContainer}>
					<img src="/icons/successFill.svg" alt="success icon" />
					<h2>Payment was successful</h2>
					<p>Thank you for your payment! You can see more information below</p>
					<div className={styles.info}>
						<div>
							<p>Network:</p>
							<span>{network.title}</span>
						</div>
						<div>
							<p>From:</p>
							<span>0x1395...dfdf</span>
						</div>
						<div>
							<p>To::</p>
							<span>0axnku2...sdrf</span>
						</div>
						<div>
							<p>Transaction Time:</p>
							<span>24 Nov 2023, 14:25</span>
						</div>
						<div className={styles.tHash}>
							<p>Transition Hash:</p>
							<span>20xhtfoj3...49kg</span>
						</div>
						<hr />
						<div className={styles.foot}>
							<p>Amount To Pay :</p>
							<span>0.00585 BNB</span>
						</div>
					</div>
					<button className={styles.viewBtn}>View Transaction</button>
					<button className={styles.receiptBtn}>Download Receipt</button>
				</div>
			</Modal>
		</div>
	);
};

export default PayWithWallet;
