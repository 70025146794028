import React, { useState } from "react";
import styles from "./PaymentNetwork.module.css";
import { networks } from "../../fakeData";

const PaymentNetwork = ({ cardStepHandler, selectedNetwork }) => {
	const [coinsData, setCoinsData] = useState(networks);
	const [selectedNet, setSelectedNet] = useState();
	const [isDisabled, setIsDisabled] = useState(false);
	const [search, setSearch] = useState("");
	const [alertmsg, setAlertmsg] = useState("");

	const handleSearch = (event) => {
		const searchTerm = event.target.value.toLowerCase();
		if (searchTerm === "") setCoinsData(networks);
		setSearch(searchTerm);

		const filteredCoins = networks.filter((item) => {
			const lowerCaseTitle = item.network_id.toLowerCase();
			const lowerCaseAbbr = item.network_name.toLowerCase();

			return lowerCaseTitle.includes(searchTerm) || lowerCaseAbbr.includes(searchTerm);
		});

		setCoinsData(filteredCoins);
	};

	const handleProcess = () => {
		setIsDisabled(true);
		setAlertmsg("");
		if (!selectedNet) {
			setAlertmsg("Please select a network !");
			setIsDisabled(false);
			return;
		}
		selectedNetwork(selectedNet);
		cardStepHandler("coin");
		setIsDisabled(false);
	};

	return (
		<div className={styles.container}>
			<div className={styles.searchbar}>
				<img src="/icons/search.svg" alt="search" />
				<input type="text" name="network" placeholder="Find Your Preferred Network" value={search} onChange={handleSearch} />
			</div>
			<div className={styles.networkList}>
				{coinsData.length > 0 ? (
					coinsData.map((item) => (
						<div key={item.id} className={`${styles.item} ${selectedNet === item ? styles.selectedItem : ""}`} onClick={() => {
							setSelectedNet(item);
							localStorage.setItem("network", item.network_id);
							localStorage.setItem("network_name", item.network_name);
						}}>
							<div>
								<img className={styles.icon} src={item.img_url} alt={item.title} />
								<h2>{item.network_name}</h2>
								<p>{item.network_id} </p>
							</div>
							<div>{selectedNet === item && <img src="/icons/selectbox.svg" alt="selectbox icon"></img>}</div>
						</div>
					))
				) : (
					<h2 style={{ color: "var(--text_color_low" }}>no network!</h2>
				)}
			</div>
			{alertmsg && <p className={styles.alertMessage}>{alertmsg}</p>}
			<button type="button" onClick={handleProcess} disabled={isDisabled}>
				Proceed
			</button>
		</div>
	);
};

export default PaymentNetwork;
